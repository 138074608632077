@use '@angular/material' as mat;
@use 'm3-theme';

markdown {
    table {
        border-spacing: 0;
        border-collapse: collapse;

        * {
            padding: 0.25em 0.5em;
            border: 1px solid mat.get-theme-color(m3-theme.$light-theme, primary);
        }

        tr:nth-child(even) {
            background-color: mat.get-theme-color(m3-theme.$light-theme, primary-container);
            color: mat.get-theme-color(m3-theme.$light-theme, on-primary-container);
        }
    }

    code {
        color: mat.get-theme-color(m3-theme.$light-theme, primary, 60);
    }

    pre {
        background-color: mat.get-theme-color(m3-theme.$light-theme, primary-container);
        color: mat.get-theme-color(m3-theme.$light-theme, on-primary-container);
        padding: 0.5em;
        display: inline-flex;
        max-width: 100%;

        code {
            overflow: auto;
        }
    }

    blockquote {
        margin-left: 0;
        border-left: 4px solid mat.get-theme-color(m3-theme.$light-theme, secondary-container);
        padding-left: 1em;
    }
}