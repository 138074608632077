@use '@angular/material' as mat;
@use 'm3-theme';
@import 'markdown';

@include mat.core();

// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

html {
  @include mat.all-component-themes(m3-theme.$light-theme);
}

html, body {
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: Roboto;
}

app-root {
    height: 100%;

    /* Flexbox for toolbar and page content */
    display: flex;
    flex-direction: column;
}

a {
    color: mat.get-theme-color(m3-theme.$light-theme, secondary);
}

ul {
    list-style-type: none;
}

img.img-icon {
    height: 24px;
    width: 24px;
}

.page-content {
    padding: 1em 2em;
    color: mat.get-theme-color(m3-theme.$light-theme, on-surface);
}

.img-splash {
    text-align: center;

    img {
        width: 50vmin;
    }
}